import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CognitoService } from '../cognito-service/cognito.service';

export interface IContactSupportRequest {
  subject: string;
  message: string;
}

export interface ISendEmailResponse {
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class ContactSupportService {
  private subscription = new Subscription();

  constructor(private cognito: CognitoService, private http: HttpClient) {}

  /**
   * TODO: Create an interceptor file to add token there
   * @returns
   */
  private async composeHeaders(): Promise<{ headers: HttpHeaders }> {
    const idToken = await this.cognito.getIdToken();
    return {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + idToken,
      }),
    };
  }

  public async sendEmail(
    body: IContactSupportRequest
  ): Promise<ISendEmailResponse> {
    this.subscription.unsubscribe();
    const labFolderName = this.cognito.getLabFolderNameCurrentValue();
    const httpOptions = await this.composeHeaders();
    return new Promise((resolve, reject) => {
      this.http
        .post<ISendEmailResponse>(
          environment.gatewayURL + '/contact-support?prefix=' + labFolderName,
          body,
          httpOptions
        )
        .pipe(take(1))
        .subscribe((response) => resolve(response));
    });
  }
}
