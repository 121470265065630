<h1 mat-dialog-title class="about-title">About {{ brandName() }} Lab Portal</h1>
<div mat-dialog-content mat-body-1 class="about-dialog-content">
  <p>Version: {{ version() }}</p>
  <p>Build mode: {{ modeString() }}</p>
  <p class="copyright-paragraph">
    <mat-icon>copyright</mat-icon>
    <span class="copyright-text"
      >{{ brandName() }} 2022-{{ 'currentYear' | currentYear }}</span
    >
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true" aria-label="OK">OK</button>
</div>
