/**
 * This is for test environment for labportal's prod.
 */
import packageJson from 'package.json';

export const environment = {
  brandName: 'EMR Data Cloud',
  brandLogo: 'assets/emrlogo.png',
  brandFavicon: 'EMRdatacloudFavicon.ico',
  production: true,
  dev: true,
  version: packageJson.version,
  gatewayURL: 'https://gateway.dev.labportal.geneicd.com',
  resultGatewayUrl:
    'https://result-delivery.labportal.geneicd.com/dev/sendtoemr',
  resultGatewayKey: '1TmyNzgFct21MvY7fRsN2niP95GFngdXj3QBaF40',
  aws: {
    region: 'us-east-2',
    // For User Pool lab-portal-devs-only
    cognitoPool: {
      UserPoolId: 'us-east-2_zHeyGHCHW', // user pool id
      ClientId: 'odd3tqb7koke816c9kjijge48', // client id
    },
    bucketName: 'geneicd-patient-data-dev',
  },
};
