<h1 mat-dialog-title class="dialog-title">Contact Support</h1>
<mat-dialog-content class="dialog-content">
  <div *ngIf="sending || hideFields" class="sending-text">
    Sending message...
    <mat-progress-bar
      *ngIf="sending"
      mode="indeterminate"
      color="primary"
    ></mat-progress-bar>
  </div>
  <span class="success-message" @fadeInAnimation *ngIf="response">
    {{ response }}
  </span>
  <br />
  <form #contactForm="ngForm">
    <div style="max-width: 80ch" *ngIf="!hideFields">
      <p>
        Please send a message to {{ brandName() }} support. We will respond as
        soon as we can.
      </p>
      <p>
        <mat-form-field style="width: 70%" appearance="outline">
          <mat-label>Subject</mat-label>
          <input
            matInput
            [(ngModel)]="subject"
            name="subject"
            class="subject-input"
            required
          />
          <mat-error> Please enter subject! </mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field style="width: 100%" appearance="outline">
          <mat-label>Message</mat-label>
          <textarea
            matInput
            [(ngModel)]="message"
            name="message"
            rows="3"
            style="width: 100%"
            placeholder="How can we help?"
            required
          ></textarea>
          <mat-error> Please enter your message! </mat-error>
        </mat-form-field>
      </p>
    </div>
  </form>
</mat-dialog-content>
<div mat-dialog-actions>
  <button
    mat-raised-button
    [disabled]="!subject || !message"
    color="primary"
    *ngIf="!hideFields"
    (click)="send()"
  >
    Send Message
  </button>
  <button
    mat-button
    mat-dialog-close
    class="contact-support-cancel-button"
    aria-label="{{ hideFields ? 'Close' : 'Cancel' }}"
  >
    {{ hideFields ? 'Close' : 'Cancel' }}
  </button>
</div>
