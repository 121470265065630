import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { COLOR } from 'src/app/shared/constants';
import { AlertDialogComponent } from '../../shared/dialogs/alert-dialog/alert-dialog.component';
import { newAlertDialogOptions } from '../../shared/dialogs/dialog-options';
import { LogService } from '../log-service/log.service';
import { ErrorMessage, ErrorMessages } from './error-messages';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandler {
  constructor(public dialog?: MatDialog, public logService?: LogService) {}

  showMessage(message: ErrorMessage) {
    this.dialog?.open(AlertDialogComponent, {
      data: newAlertDialogOptions(
        message.title,
        message.message,
        undefined,
        COLOR.geneicd_red
      ),
    });
  }

  handle(error: Error) {
    let errorMessage;
    switch (true) {
      case error instanceof PostResourceFailed:
        this.showMessage(ErrorMessages.post_resource_error);
        break;
      case error instanceof ListResourceIdsFailed:
        this.showMessage(ErrorMessages.list_resource_ids_error);
        break;
      case error instanceof GetResourceFailed:
        this.showMessage(ErrorMessages.get_resource_error);
        break;
      case error instanceof LoginFailed:
        errorMessage = { ...ErrorMessages.login_error };
        if (error.message) {
          errorMessage.message = error.message; // Display error from cognito - like wrong password
          errorMessage.title = errorMessage.log;
        }
        this.showMessage(errorMessage);
        break;
      case error instanceof RequestVerificationCodeFailed:
        errorMessage = { ...ErrorMessages.verification_code_error };
        if (error.message) {
          errorMessage.message = error.message; // Display error from cognito - like limit exceeded
          errorMessage.title = errorMessage.log;
        }
        this.showMessage(errorMessage);
        break;
      case error instanceof ResetPasswordFailed:
        errorMessage = { ...ErrorMessages.reset_password_error };
        if (error.message) {
          errorMessage.message = error.message; // Display error from cognito - like verification code is invalid
          errorMessage.title = errorMessage.log;
        }
        this.showMessage(errorMessage);
        break;
      default:
        this.showMessage(ErrorMessages.internal_server_error);
    }
  }
}

export class PostResourceFailed extends Error {}
export class ListResourceIdsFailed extends Error {}
export class GetResourceFailed extends Error {}
export class LoginFailed extends Error {}
export class RequestVerificationCodeFailed extends Error {}
export class ResetPasswordFailed extends Error {}
export class GetDynamoDbItemFailed extends Error {}
export class ScanDynamoDbItemsFailed extends Error {}
export class ApplicationError extends Error {}
