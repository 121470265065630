import { Injectable } from '@angular/core';

export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6,
}

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor() {}

  private logger(message: string, messageType: LogLevel) {
    if (messageType != LogLevel.Error) {
      // eslint-disable-next-line no-console
      console.log(message);
    } else {
      // eslint-disable-next-line no-console
      console.error(message);
    }
  }

  public error(message = '') {
    if (message) {
      this.logger(message, LogLevel.Error);
    }
  }

  public message(message = '') {
    if (message) {
      this.logger(message, LogLevel.All);
    }
  }
}
