import { Component } from '@angular/core';
import {
  ContactSupportService,
  IContactSupportRequest,
} from 'src/app/services/contact-support-service/contact-support.service';
import { environment } from 'src/environments/environment';
import {
  FADE_IN_ANIMATION,
  FADE_OUT_ANIMATION,
} from '../../animations/animations';

@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss'],
  animations: [FADE_IN_ANIMATION, FADE_OUT_ANIMATION],
})
export class ContactSupportComponent {
  constructor(private contactSupportService: ContactSupportService) {}
  subject: string;
  message: string;
  response: string;
  sending = false;
  hideFields: boolean = false;

  async send() {
    const body: IContactSupportRequest = {
      subject: this.subject,
      message: this.message,
    };
    this.response = '';
    this.sending = true;
    this.hideFields = true;
    const rawResponse = await this.contactSupportService.sendEmail(body);
    this.response = rawResponse.message;
    this.sending = false;
  }

  public brandName(): string {
    return environment.brandName;
  }
}
