import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  LabName,
  labNameFromLabFolderName,
  LAB_FOLDER_NAME,
} from '@geneicd/stakeholder-resources';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  IAuthenticationDetailsData,
  ICognitoUserData,
} from 'amazon-cognito-identity-js';
import jwtDecode from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { NewPasswordDialogComponent } from 'src/app/shared/dialogs/new-password-dialog/new-password-dialog.component';
import { ForgotPasswordDialogData } from '../../shared/dialogs/forgot-password-dialog/forgot-password-dialog.component';
import {
  RequestVerificationCodeFailed,
  ResetPasswordFailed,
} from '../error-handler-service/error-handler.service';
import { LogService } from '../log-service/log.service';
import { UtilService } from '../util-service/util.service';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  private userPool = new CognitoUserPool(environment.aws.cognitoPool);
  private userData: ICognitoUserData;
  private cognitoUser: CognitoUser | null;
  private newPassword: string;
  private sessionUserAttributes: any;
  private requiredAttributes: string[];
  private userDisplayName = new BehaviorSubject<string>('');
  private labName = new BehaviorSubject<LabName>(LabName.none);
  private labFolderName = new BehaviorSubject<LAB_FOLDER_NAME>(
    LAB_FOLDER_NAME.none
  );

  constructor(
    public dialog: MatDialog,
    private utilService: UtilService,
    private logService: LogService
  ) {}

  // testing the authenticate method turned into callback hell
  // testing removed
  // technical debt: Is there a better way to do this?
  /*istanbul ignore next*/
  authenticate(user: IAuthenticationDetailsData): Observable<string> {
    this.userData = {
      Username: user.Username,
      Pool: this.userPool,
    };
    const authenticationDetails = new AuthenticationDetails(user);
    this.cognitoUser = new CognitoUser(this.userData);
    return new Observable((observer) => {
      let self = this;
      this.cognitoUser?.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          const accessToken: string = result.getAccessToken().getJwtToken();
          observer.next(accessToken);
          self.setUserDisplayName();
          self.setLabNameAndLabFolderName(accessToken);
        },
        mfaRequired: function (codeDeliveryDetails) {
          // MFA is required to complete user authentication.
          // Get the code from user and call
          const mfaCode: string =
            prompt(
              'We have delivered the authentication code by SMS to you. Please enter the code to complete authentication',
              ''
            ) || '';
          self.cognitoUser?.sendMFACode(mfaCode, this);
        },
        onFailure: function (err) {
          /*eslint-disable no-console*/ console.error(
            'json',
            err.message || JSON.stringify(err)
          );
          observer.error(err);
        },
        newPasswordRequired: function (userAttributes, requiredAttributes) {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.

          // the api doesn't accept this field back
          delete userAttributes.email_verified;
          delete userAttributes.phone_number_verified;
          delete userAttributes.phone_number;
          // store userAttributes on global variable
          self.sessionUserAttributes = userAttributes;
          self.requiredAttributes = requiredAttributes;
          self.openDialog(this);
        },
      });
    });
  }

  openDialog(callback: any): void {
    const dialogRef = this.dialog.open(NewPasswordDialogComponent, {
      width: '500px',
      data: {
        newPassword: this.newPassword,
        requiredAttributes: this.requiredAttributes.reverse(),
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      this.handleNewPassword(data, callback);
    });
  }

  handleNewPassword(data: any, callback: any) {
    this.cognitoUser?.completeNewPasswordChallenge(
      data.newPassword,
      { ...this.sessionUserAttributes, ...data.userAttributes },
      callback
    );
  }

  setCognitoUser(username = '') {
    if (username) {
      const data: ICognitoUserData = {
        Username: username,
        Pool: new CognitoUserPool(environment.aws.cognitoPool),
      };
      this.cognitoUser = new CognitoUser(data);
      this.cognitoUser.getSession(() => {});
    }
  }

  // got bogged down writing tests for this
  // technical debt: Can this be covered with tests?
  /* istanbul ignore next */
  getUserAttributes(): Promise<CognitoUserAttribute[]> {
    if (this.cognitoUser) {
      return new Promise<CognitoUserAttribute[]>((resolve, reject) => {
        try {
          this.cognitoUser?.getUserAttributes((error, data) => {
            if (error) {
              console.error('get user attributes error', error);
              reject([]);
            } else {
              resolve(data || []);
            }
          });
        } catch (error) {
          this.logService.error(JSON.stringify(error));
          reject([]);
        }
      }).catch((error) => {
        console.error('error getting user attributes', error);
        return Promise.reject([]);
      });
    } else {
      return Promise.resolve([]);
    }
  }

  getCurrentCognitoUser(): CognitoUser | null {
    const userPool = new CognitoUserPool(environment.aws.cognitoPool);
    return userPool.getCurrentUser();
  }

  /**
   * Set current congnito session
   */
  setCurrentCognitoUser() {
    this.cognitoUser = this.getCurrentCognitoUser();
  }

  async getUsername(idToken: string = '') {
    if (!idToken) {
      idToken = await this.getIdToken();
    }
    const payload = this.utilService.decodeToken(idToken);
    return (payload?.given_name + ' ' + payload?.family_name).trim();
  }

  getIdToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      // TODO: is there a way to test the callback paths?
      /* istanbul ignore if */
      if (this.cognitoUser) {
        this.cognitoUser.getSession(
          (error: Error | null, result: CognitoUserSession | null) => {
            if (result) {
              return resolve(result.getIdToken().getJwtToken());
            } else {
              return reject(error);
            }
          }
        );
      } else {
        return reject('User not defined');
      }
    });
  }

  async setUserDisplayName() {
    const username = await this.getUsername();
    this.userDisplayName.next(username);
  }

  getUserDisplayName(): Observable<string> {
    return this.userDisplayName.asObservable();
  }

  eraseUserDisplayName() {
    this.userDisplayName.next('');
  }

  fetchLabFolderNameFromToken(token: string): LAB_FOLDER_NAME {
    let labFolderName = LAB_FOLDER_NAME.none;
    if (token) {
      const decodedToken = jwtDecode(token);
      /*istanbul ignore next*/
      const groups = ((decodedToken as any)['cognito:groups'] ||=
        []) as string[];
      labFolderName = (groups[0] ||
        /*istanbul ignore next*/ '') as LAB_FOLDER_NAME;
    }
    return labFolderName;
  }

  fetchLabNameFromToken(token: string): LabName {
    return labNameFromLabFolderName(this.fetchLabFolderNameFromToken(token));
  }

  setLabNameAndLabFolderName(token = '') {
    this.labName.next(this.fetchLabNameFromToken(token));
    this.labFolderName.next(this.fetchLabFolderNameFromToken(token));
  }

  getLabName(): Observable<LabName> {
    return this.labName.asObservable();
  }

  getLabNameCurrentValue(): LabName {
    return this.labName.getValue();
  }

  getLabFolderName(): Observable<LAB_FOLDER_NAME> {
    return this.labFolderName.asObservable();
  }

  getLabFolderNameCurrentValue(): LAB_FOLDER_NAME {
    return this.labFolderName.getValue();
  }

  eraseLabNameAndLabFolderName() {
    this.labName.next(LabName.none);
    this.labFolderName.next(LAB_FOLDER_NAME.none);
  }

  requestForgottenPasswordVerificationCode(username: string): Promise<boolean> {
    this.setCognitoUser(username);
    return new Promise<boolean>((resolve, reject) => {
      this.cognitoUser?.forgotPassword({
        onSuccess: /* istanbul ignore next */ () => resolve(true),
        onFailure: /* istanbul ignore next */ (error: any) => {
          this.logService.error(JSON.stringify(error?.message));
          if (error.code !== 'NetworkError') {
            return reject(new RequestVerificationCodeFailed(error?.message));
          }
          return reject(new RequestVerificationCodeFailed());
        },
      });
    });
  }

  resetForgottenPassword(
    dialogData: ForgotPasswordDialogData
  ): Promise<boolean> {
    if (!this.cognitoUser) {
      this.setCognitoUser(dialogData.username);
    }
    const verificationCode = dialogData.verificationCode;
    const newPassword = dialogData.newPassword;
    if (verificationCode && newPassword) {
      return new Promise<boolean>((resolve, reject) => {
        this.cognitoUser?.confirmPassword(verificationCode, newPassword, {
          onSuccess: /* istanbul ignore next */ () => resolve(true),
          onFailure: /* istanbul ignore next */ (error: any) => {
            this.logService.error(JSON.stringify(error?.message));
            if (error.code !== 'NetworkError') {
              return reject(new ResetPasswordFailed(error.message));
            }
            return reject(new ResetPasswordFailed());
          },
        });
      });
    } else {
      return Promise.resolve(false);
    }
  }
}
