import { Component, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth-service/auth.service';

@Component({
  selector: 'app-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss'],
})
export class MainToolbarComponent {
  public title = environment.brandName;
  public logo = environment.brandLogo;
  public isAuthenticated: Observable<boolean> =
    this.authService.isAuthenticated$;
  @Output() logout = new EventEmitter<boolean>();
  @Output() about = new EventEmitter<boolean>();
  @Output() support = new EventEmitter<boolean>();

  constructor(private authService: AuthService) {}

  public logoutRequested() {
    this.logout.emit(true);
  }

  public aboutRequested() {
    this.about.emit(true);
  }

  public supportRequested() {
    this.support.emit(true);
  }
}
