import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth-service/auth.service';
import { COLOR } from './shared/constants';
import { AboutDialogComponent } from './shared/dialogs/about-dialog/about-dialog.component';
import { ConfirmDialogComponent } from './shared/dialogs/confirm-dialog/confirm-dialog.component';
import { ContactSupportComponent } from './shared/dialogs/contact-support/contact-support.component';
import { DialogOptions } from './shared/dialogs/dialog-options';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private siteTitle: Title,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit() {
    this.siteTitle.setTitle(environment.brandName);
    this._document
      .getElementById('dynamic-favicon')!
      .setAttribute('href', environment.brandFavicon);
  }

  public async logout() {
    const options: DialogOptions = {
      title: 'Sign out',
      message: 'Are you sure you want to sign out?',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      titleColor: COLOR.geneicd_red,
      showContactSupport: false,
    };
    const openDialog = this.dialog
      .open(ConfirmDialogComponent, { data: options })
      .afterClosed();
    await lastValueFrom(openDialog).then(
      (x) => x && this.authService.logout('/')
    );
  }

  public showAbout() {
    this.dialog.open<AboutDialogComponent>(AboutDialogComponent);
  }

  public showContactSupport() {
    this.dialog.open<ContactSupportComponent>(ContactSupportComponent);
  }
}
