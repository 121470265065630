<h1 mat-dialog-title>Please enter your new password below.</h1>
<form #loginForm="ngForm" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        matInput
        type="password"
        placeholder="New Password"
        [(ngModel)]="data.newPassword"
        name="oldPassword"
        required
        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
        title="Must contain at least one number and at least one uppercase and one lowercase letter, and be at least 8 characters long"
      />
      <mat-error> Please provide a valid password </mat-error>
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        type="password"
        placeholder="Confirm New Password"
        [(ngModel)]="confirmNewPassword"
        name="confirmNewPassword"
        required
        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
        title="Must match password above"
      />
      <mat-error> Please provide a valid password </mat-error>
    </mat-form-field>

    <mat-form-field *ngFor="let attribute of data.requiredAttributes">
      <input
        matInput
        type="text"
        placeholder="{{ attributesMapping[attribute] }}"
        [(ngModel)]="userAttributes[attribute]"
        [name]="attribute"
        required
      />
      <mat-error> Please provide given attributes </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button
      mat-raised-button
      color="primary"
      [disabled]="data.newPassword !== confirmNewPassword"
    >
      Submit
    </button>
  </div>
</form>
