<mat-toolbar color="primary">
  <button
    mat-button
    [matMenuTriggerFor]="menu"
    aria-label="Menu"
    id="hamburger-menu"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="supportRequested()">
      <mat-icon>manage_accounts</mat-icon>
      Support
    </button>
    <button mat-menu-item (click)="aboutRequested()">
      <mat-icon>help_outline</mat-icon>
      About
    </button>
    <button
      mat-menu-item
      (click)="logoutRequested()"
      *ngIf="isAuthenticated | async"
      aria-label="Sign out"
      id="menu-sign-out"
    >
      <mat-icon>power_settings_new</mat-icon>Sign out
    </button>
  </mat-menu>
  <span mat-body-2 class="logo">
    <img
      _ngcontent-uem-c128=""
      class="ng-tns-c128-2"
      width="32px"
      height="32px"
      src="{{ logo }}"
      alt="{{ title }}"
    />
  </span>
  <!-- <span mat-body-2>{{ title }}</span> -->
  <span class="toolbar-spacer"></span>
  <div fxLayout="row" fxShow="false" fxShow.gt-sm>
    <button
      mat-button
      (click)="supportRequested()"
      *ngIf="isAuthenticated | async"
      aria-label="Support"
      id="support"
    >
      <mat-icon>manage_accounts</mat-icon>
      Support
    </button>
    <button mat-button (click)="aboutRequested()" aria-label="About" id="about">
      <mat-icon>help_outline</mat-icon>
      About
    </button>
    <button
      mat-button
      (click)="logoutRequested()"
      *ngIf="isAuthenticated | async"
      aria-label="Sign out"
      id="sign-out"
    >
      <mat-icon>power_settings_new</mat-icon>
      Sign out
    </button>
  </div>
  <button
    mat-button
    [mat-menu-trigger-for]="menu"
    fxHide="false"
    fxHide.gt-sm
    aria-label="Menu"
    id="menu"
  >
    <mat-icon>menu</mat-icon>
  </button>
</mat-toolbar>
