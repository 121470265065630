import { Component } from '@angular/core';
import { AboutService } from 'src/app/services/about-service/about.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
})
export class AboutDialogComponent {
  constructor(private about: AboutService) {}

  public modeString(): string {
    return this.about.buildModeString();
  }

  public version(): string {
    return this.about.version();
  }

  public brandName(): string {
    return environment.brandName;
  }
}
