import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  ForgotPasswordDialogComponent,
  ForgotPasswordDialogData,
} from '../../shared/dialogs/forgot-password-dialog/forgot-password-dialog.component';
import { CognitoService } from '../cognito-service/cognito.service';
import { LoginFailed } from '../error-handler-service/error-handler.service';
import { ErrorMessages } from '../error-handler-service/error-messages';
import { LogService } from '../log-service/log.service';
import { UtilService } from '../util-service/util.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private _authSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public get isAuthenticated$(): Observable<boolean> {
    return this._authSub$.asObservable();
  }

  constructor(
    private router: Router,
    private cognitoService: CognitoService,
    private dialog: MatDialog,
    private utilService: UtilService,
    private logService: LogService
  ) {
    this.checkForLocalToken();
  }

  ngOnDestroy(): void {
    this._authSub$.next(false);
    this._authSub$.complete();
  }

  public checkForLocalToken() {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      const payload = this.utilService.decodeToken(accessToken);
      this._authSub$.next(new Date().getTime() < payload.exp * 1000);
      this.cognitoService.setCurrentCognitoUser();
      this.cognitoService.setLabNameAndLabFolderName(accessToken);
      this.cognitoService.setUserDisplayName();
    }
  }

  public login(username: string, password: string): Observable<void> {
    return this.cognitoService
      .authenticate({
        Username: username,
        Password: password,
      })
      .pipe(
        map((accesToken: string) => this.handleSignInResponse(accesToken)),
        catchError((error: any) => {
          this.logService.error(ErrorMessages.login_error.log);
          this.logService.error(error.message); // Log error from http
          if (error.code !== 'NetworkError') {
            return throwError(() => new LoginFailed(error?.message));
          }
          return throwError(() => new LoginFailed());
        })
      );
  }

  public logout(redirect: string) {
    this._authSub$.next(false);
    this.cognitoService.eraseUserDisplayName();
    this.cognitoService.eraseLabNameAndLabFolderName();
    localStorage.removeItem('access_token');
    this.router.navigate([redirect]);
  }

  private handleSignInResponse(accesToken: string): void {
    this._authSub$.next(true);
    localStorage.setItem('access_token', accesToken);
  }

  public async forgotPassword(username = '') {
    await this.dialog
      .open<
        ForgotPasswordDialogComponent,
        ForgotPasswordDialogData,
        ForgotPasswordDialogData
      >(ForgotPasswordDialogComponent, {
        width: '450px',
        data: {
          newPassword: '',
          username,
          verificationCode: '',
        },
      })
      .afterClosed();
  }
}
