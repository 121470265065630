import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, NEVER, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth-service/auth.service';
import { CognitoService } from './services/cognito-service/cognito.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(
    private cognito: CognitoService,
    private authService: AuthService,
    private router: Router
  ) {}
  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.isAuthenticated$.pipe(
      switchMap((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.router.navigateByUrl('/');
          return NEVER;
        }

        return from(this.cognito.getIdToken()).pipe(
          switchMap((token) => {
            const headers: any = {};
            if (httpRequest.url.indexOf('result-delivery') > -1) {
              headers['x-api-key'] = environment.resultGatewayKey;
            } else {
              headers['Authorization'] = 'Bearer ' + token;
            }
            headers['Content-Type'] = 'application/json';
            const requestClone = httpRequest.clone({
              setHeaders: { ...headers },
            });
            return next.handle(requestClone);
          })
        );
      })
    );
  }
}
