import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const ERROR_MESSAGE_SUFFIX = `please contact ${environment.brandName} Support at support@geneicd.com.`;

export const ErrorMessages = {
  post_resource_error: {
    title: 'Failed to Upload Resource',
    message: `We ran into an error when trying to upload. Please check your connectivity. If you are connected to the internet and cannot upload, ${ERROR_MESSAGE_SUFFIX}`,
  },
  get_resource_error: {
    log: 'Failed to Fetch Resource',
    title: 'Cloud Connection Error',
    message: `We are unable to connect to the ${environment.brandName}. Please check your internet connection. If you are connected to the internet and cannot proceed, ${ERROR_MESSAGE_SUFFIX}`,
  },
  list_resource_ids_error: {
    log: 'Failed to List Resource IDs',
    title: 'Cloud Connection Error',
    message: `We are unable to connect to the ${environment.brandName}. Please check your internet connection. If you are connected to the internet and cannot proceed, ${ERROR_MESSAGE_SUFFIX}`,
  },
  login_error: {
    log: 'Falied to Login',
    title: 'Cloud Connection Error',
    message: `We are unable to connect to the ${environment.brandName}. Please check your internet connection. If you are connected to the internet and cannot proceed, ${ERROR_MESSAGE_SUFFIX}`,
  },
  verification_code_error: {
    log: 'Failed to Fetch Verification Code',
    title: 'Cloud Connection Error',
    message: `We are unable to connect to the ${environment.brandName}. Please check your internet connection. If you are connected to the internet and cannot proceed, ${ERROR_MESSAGE_SUFFIX}`,
  },
  reset_password_error: {
    log: 'Failed to Reset Password',
    title: 'Cloud Connection Error',
    message: `We are unable to connect to the ${environment.brandName}. Please check your internet connection. If you are connected to the internet and cannot proceed, ${ERROR_MESSAGE_SUFFIX}`,
  },
  get_dynamodb_item: {
    log: 'Failed to get item',
    title: 'Cloud Connection Error',
    message: `We are unable to connect to the ${environment.brandName} database. Please try again later. ${ERROR_MESSAGE_SUFFIX}`,
  },
  internal_server_error: {
    title: 'Error',
    message: 'Somthing went wrong.',
  },
};

@Injectable({
  providedIn: 'root',
})
export class ErrorMessage {
  title: string;
  message: string;
  log?: string;
}
