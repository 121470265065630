<h1 mat-dialog-title class="title">Forgot Password</h1>

<div mat-dialog-content>
  <div *ngIf="showMessage">
    <div class="alert alert-success" role="alert">
      Password successfully changed.
    </div>
    <button mat-raised-button [mat-dialog-close]="true" color="primary">
      Close
    </button>
  </div>
  <div *ngIf="!showMessage">
    <p class="instructions">
      Enter your email address. Then click the button to be sent a verification
      code.
    </p>
    <mat-form-field appearance="fill">
      <mat-label>Email</mat-label>
      <input
        matInput
        type="email"
        placeholder="example@example.com"
        [(ngModel)]="data.username"
        required
      />
      <mat-error> Please provide your registered email address </mat-error>
    </mat-form-field>
    <button
      mat-raised-button
      id="verificationCodeButton"
      class="verification-code-button"
      color="accent"
      [disabled]="!enableCodeRequest()"
      (click)="requestVerificationCode()"
    >
      Send verification code
    </button>
    <span class="code-sent-message" @fadeInAnimation *ngIf="showCodeSent">
      Code sent!
    </span>
    <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
      <p class="after-code-instructions">
        Enter the verification code and your new password below.
      </p>
      <p class="password-instructions">
        The password must be at least 8 characters long, and must contain at
        least one uppercase letter, at least one lowercase letter, at least one
        number, and at least one special character.
      </p>
      <mat-form-field appearance="fill">
        <mat-label>Verification Code</mat-label>
        <input
          matInput
          type="text"
          placeholder="123456"
          [(ngModel)]="data.verificationCode"
          [ngModelOptions]="{ standalone: true }"
          required
          pattern="^[0-9]+$"
          autocomplete="one-time-code"
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>New Password</mat-label>
        <input
          matInput
          type="password"
          placeholder="New Password"
          [(ngModel)]="data.newPassword"
          [ngModelOptions]="{ standalone: true }"
          required
          autocomplete="new-password"
        />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Confirm New Password</mat-label>
        <input
          matInput
          type="password"
          placeholder="Confirm New Password"
          [(ngModel)]="confirmNewPassword"
          [ngModelOptions]="{ standalone: true }"
          required
          autocomplete="new-password"
          title="Must match password above"
        />
      </mat-form-field>
      <div>
        <button
          mat-raised-button
          color="primary"
          [disabled]="
            !data ||
            !data.newPassword ||
            !data.verificationCode ||
            data.newPassword !== confirmNewPassword ||
            data.verificationCode.length === 0 ||
            data.newPassword.length < 8
          "
        >
          Submit Code and New Password
        </button>
      </div>
    </form>
  </div>
</div>
